export var MetricsEventType;
(function (MetricsEventType) {
    MetricsEventType["AccordionPanelExpand"] = "click:auth0-docs:expand-accordion-panel";
    MetricsEventType["AccordionPanelCollapse"] = "click:auth0-docs:close-accordion-panel";
    MetricsEventType["AccordionSearchExpandAll"] = "find:auth0-docs:expand-all-accordion-panels-on-search";
    MetricsEventType["AccordionPanelTimeOpen"] = "change:auth0-docs:accordion-panel-time-open";
    MetricsEventType["ApiDocsLanguageSelection"] = "submit:auth0-docs:api-docs-lang-selection";
    MetricsEventType["TabPanelClick"] = "click:auth0-docs:tab-panel-click";
    MetricsEventType["TabPanelTimeOpen"] = "change:auth0-docs:tab-panel-time-open";
    MetricsEventType["FeedbackSubmitComment"] = "submit:auth0-docs:feedback-comment";
    MetricsEventType["FeedbackBooleanClick"] = "click:auth0-docs:feedback-boolean";
    MetricsEventType["DefinitionTooltipOpen"] = "hover:auth0-docs:open-definition-tooltip";
    MetricsEventType["DefinitionTooltipClick"] = "click:auth0-docs:follow-definition-tooltip-link";
    MetricsEventType["LoginButtonClick"] = "click:auth0-docs:login";
})(MetricsEventType || (MetricsEventType = {}));
