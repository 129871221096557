var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { ColorSchemeMenu } from '@a0/docs-components/ui/Header/ColorSchemeMenu';
import { HamburgerMenu } from '@a0/docs-components/ui/Header/HamburgerMenu';
import { AppBar, AppBarActions, AppBarContent, Box, ColumnLayout, styled, useTheme } from '@auth0/quantum-product';
import Collapse from '@mui/material/Collapse';
import * as React from 'react';
import { SearchBox } from '../SearchBox';
import { ActionArea } from './ActionArea';
import { Logo } from './Logo';
import { NavigationDrawer } from './NavigationDrawer';
import { NavLinks } from './NavLinks';
var HeaderBar = styled(AppBar, {
    shouldForwardProp: function (prop) { return !['fixed', 'slideOut', 'isHomePage', 'mode'].includes(prop); },
})(function (_a) {
    var _b;
    var theme = _a.theme, fixed = _a.fixed, slideOut = _a.slideOut, mode = _a.mode, isHomePage = _a.isHomePage;
    return (_b = {
            padding: theme.spacing(0, 3),
            backgroundColor: fixed && isHomePage && mode !== 'dark' ? theme.palette.background.homePage : theme.tokens.color_bg_page,
            position: fixed ? 'fixed' : 'relative'
        },
        _b[theme.breakpoints.down(theme.layout.tabletBreakpoint)] = {
            padding: 0,
        },
        _b);
});
var HeaderContent = styled(AppBarContent)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            maxWidth: theme.layout.header1440,
            width: 'inherit',
            padding: 0,
            overflow: 'hidden',
            borderBottom: "1px solid ".concat(theme.tokens.color_border_default)
        },
        _b[theme.breakpoints.down(theme.layout.tabletBreakpoint)] = {
            maxWidth: '100%',
            padding: theme.spacing(0, theme.layout.mobileMarginX),
        },
        _b);
});
var ColumnStack = styled(ColumnLayout)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            gridTemplateColumns: 'none',
            gridAutoColumns: 'min-content',
            gridAutoFlow: 'column',
            alignItems: 'center'
        },
        _b[theme.breakpoints.up('md')] = {
            gridTemplateColumns: 'none',
        },
        _b);
});
export var Header = function (_a) {
    var currentSection = _a.currentSection, currentRouteName = _a.currentRouteName, sections = _a.sections, _b = _a.isAuthenticated, isAuthenticated = _b === void 0 ? false : _b, user = _a.user, bannerVisibility = _a.bannerVisibility;
    var isHomePage = currentRouteName === 'home';
    var routeNames = ['home', 'quickstarts', 'article', 'library'];
    var isFixedHeader = routeNames.includes(currentRouteName);
    var _c = __read(React.useState(false), 2), showSearch = _c[0], setShowSearch = _c[1];
    var _d = __read(React.useState(undefined), 2), backgroundColor = _d[0], setBackgroundColor = _d[1];
    var _e = __read(React.useState(false), 2), expandMenu = _e[0], setExpandMenu = _e[1];
    var _f = __read(React.useState(undefined), 2), marginTop = _f[0], setMarginTop = _f[1];
    var headerElRef = React.useRef(null);
    var theme = useTheme();
    var handleMenuToggle = function (force) {
        var shouldExpandMenu = typeof force === 'boolean' ? force : !expandMenu;
        setExpandMenu(shouldExpandMenu);
    };
    React.useEffect(function () {
        var handleScroll = function () {
            if (!headerElRef.current) {
                return;
            }
            var scrollTop = window.scrollY || 0;
            // @TODO - temp
            setBackgroundColor(scrollTop > 553 ? theme.tokens.color_bg_page : undefined);
            setShowSearch(scrollTop > headerElRef.current.offsetHeight);
        };
        if (isHomePage) {
            document.addEventListener('scroll', handleScroll);
            return function () {
                document.removeEventListener('scroll', handleScroll);
            };
        }
    }, [isHomePage, theme.palette.mode]);
    React.useEffect(function () {
        if (bannerVisibility) {
            setMarginTop('60px');
        }
        else {
            setMarginTop('0px');
        }
    }, [bannerVisibility]);
    return (React.createElement(React.Fragment, null,
        React.createElement(HeaderBar, { mode: theme.palette.mode, slideOut: expandMenu, fixed: isFixedHeader, isHomePage: isHomePage, ref: function (el) {
                headerElRef.current = el;
            }, style: { backgroundColor: backgroundColor, marginTop: marginTop }, "data-search-index": "false" },
            React.createElement(HeaderContent, null,
                React.createElement(AppBarActions, null,
                    React.createElement(Box, { sx: { marginRight: 3 } },
                        React.createElement(Logo, null))),
                React.createElement(AppBarActions, { sx: { flex: 1, justifyContent: 'space-between' } },
                    isHomePage ? (React.createElement(Collapse, { in: showSearch },
                        React.createElement(SearchBox, { placement: "header" }))) : (React.createElement(SearchBox, { placement: "header" })),
                    React.createElement(NavLinks, { sections: sections, currentSection: currentSection, onClick: function () { return handleMenuToggle(false); } })),
                React.createElement(AppBarActions, null,
                    React.createElement(ActionArea, { placement: "header", isAuthenticated: isAuthenticated, user: user })),
                React.createElement(AppBarActions, null,
                    React.createElement(ColorSchemeMenu, null)),
                React.createElement(HamburgerMenu, { expandMenu: expandMenu, onClick: function () { return handleMenuToggle(); } }))),
        React.createElement(NavigationDrawer, { isAuthenticated: isAuthenticated, user: user, show: expandMenu, onClose: function () {
                setExpandMenu(false);
            }, sections: sections, currentSection: currentSection })));
};
